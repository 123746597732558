<template>
  <div>
    <collection-image-thumbnail-index></collection-image-thumbnail-index>
  </div>
</template>

<script>
import CollectionImageThumbnailIndex from './CollectionImageThumbnailIndex.vue'

export default {
  components: {
    CollectionImageThumbnailIndex
  }
}  
</script>
