<template>
  <b-container fluid class="p-4 bg-dark text-white">
    <!-- <b-container fluid class="p-4">  -->
    <b-row>
      <b-col sm="5" md="4" class="my-1">
        <!-- <b-button squared variant="outline-primary" size="sm">Add Entry</b-button> -->
      </b-col>
      <b-col sm="5" md="4" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          pills
          align="fill"
          size="sm"
          class="my-2"
        ></b-pagination>
      </b-col>
      <b-col b-col sm="5" md="4" class="my-1" text-variant="white">
      Total {{totalRows}}
      </b-col>
    </b-row>
  <b-row>
  <b-col 
    v-for="(item,index) in items" :key="item.id"
    sm="2" md="2" class="my-1"
  >
    <b-img thumbnail fluid
    :src="imageUrl(index)"
    :alt="imageCaption(index)"
    @click="showImage(item)"
    >
    </b-img>
  </b-col>
  </b-row>

</b-container>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data() {
    return {
      api_route: 'api/admin/v1/collection_images',
      included: [],
      items: [],
      totalRows: undefined,
      currentPage: 1,
      perPage: 5,
    }
  },
  mounted() {
    this.callApi()
  },
  methods: {
    imageCaption(index) {
      return this.items[index].id
    },
    imageUrl(index) {
      return this.items[index].attributes['image-url']
    },
    callApi () {
      http_v1.get (
        this.api_route, {
          params: {
            page: this.currentPage,
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.items = response.data.data
          this.included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    showImage(item) {
      this.$router.push( {name: 'CollectionImageShowScreen', params: {id: item.id} })
    }
  },
    watch: {
    refresh(val) {
      if(val === true) {
        this.currentPage = 1
        this.callApi()
        this.$emit('refreshed', val)
      }
    },
    currentPage() {
      this.callApi()
    }
  }
}
</script>